<template>
  <div>
    <div class="search-bar flex justify-between items-center">
      <a-form-model layout="inline" class="flex-1"
                    :model="searchForm"
                    :label-col="labelCol"
                    :wrapper-col="wrapperCol">
        <a-form-model-item label="退款状态" prop="refund_status">
          <a-select placeholder="选择状态" style="width:200px;"
                    allowClear
                    v-model="searchForm.refund_status"
                    @change="handlerSearch">
            <a-select-option v-for="(item, index) in refundStatusList"
                    :key="index+1" >{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item prop="order_no" label="订单号">
          <a-input placeholder="订单号"
                  allowClear 
                  v-model="searchForm.order_no"
                  @keyup.enter="handlerSearch"></a-input>
        </a-form-model-item>
      </a-form-model>
      <div>
        <a-button class1="ml-2" @click="resetForm">重置</a-button>
        <a-button class="ml-2" @click="handlerSearch" type="primary">搜索</a-button>
      </div>
    </div>

    <div class="flex items-center">
      <span class="text-lg font-bold p-3">订单退款流水</span>
    </div>

    <base-table
        :columnsData="columns"
        :tableData="tableData"
        :total="total"
        :getListFunc="initData"
        :page.sync="searchForm.page"
        :pageSize.sync="searchForm.page_count">
      <template #order_no="{text}">
        <a-button class="p-0" type="link" @click="handlerOrderDetail(text)">{{text}}</a-button>
      </template>
      <template #operation="{record}">
        <a-button class="p-0" type="link" @click="handlerDetail(record)">查看</a-button>
      </template>
    </base-table>

    <!-- 新增 编辑modal -->
    <a-modal v-if="isShowDetail" :visible.sync="isShowDetail"
      width="600px"
      title="退款流水详情"
      :footer="null"
      @cancel="isShowDetail=false">
      <a-form-model :labelCol="{ span: 4 }" :wrapperCol="{ span: 20 }"
        v-if="detailData">
        <a-form-model-item class="mb-0" label="订单号">
          <span>{{detailData.order_no}}</span>
        </a-form-model-item>
        <a-form-model-item class="mb-0" label="退款方式">
          <span>{{detailData.refund_mode}}</span>
        </a-form-model-item>
        <a-form-model-item class="mb-0" label="金额">
          <span>￥ {{detailData.amount}}</span>
        </a-form-model-item>
        <a-form-model-item class="mb-0" label="财务账户">
          <span>{{detailData.financial_account}}</span>
        </a-form-model-item>
        <a-form-model-item class="mb-0" label="退款流水号">
          <span>{{detailData.refund_flow_no}}</span>
        </a-form-model-item>
        <a-form-model-item class="mb-0" label="第三方流水号" v-if="detailData.refund_serial_no">
          <span>{{detailData.refund_serial_no}}</span>
        </a-form-model-item>
        <a-form-model-item class="mb-0" label="人工录入流水号" v-if="detailData.artificial_refund_flow_no">
          <span>{{detailData.artificial_refund_flow_no}}</span>
        </a-form-model-item>
        <!-- <a-form-model-item class="mb-0" label="退款凭证" v-if="detailData.attached_images">
          <span>{{detailData.attached_images_list}}</span>
        </a-form-model-item> -->
        <a-form-model-item class="mb-0" label="操作人" v-if="detailData.operator">
          <span>{{detailData.operator}}</span>
        </a-form-model-item>
        <a-form-model-item class="mb-0" label="发起退款时间">
          <span>{{detailData.add_time}}</span>
        </a-form-model-item>
        <a-form-model-item class="mb-0" label="退款状态">
          <div>
            <div>{{detailData.refund_status_show}}</div>
            <template v-if="detailData.refund_status == 3">
              <div class="flex">{{detailData.refundsucc_time}}</div>
            </template>
            <template v-if="detailData.refund_status == 4">
              <div class="flex">{{detailData.refunderr_time}}</div>
              <div class="flex" v-if="detailData.refunderr_reason">
                {{detailData.refunderr_reason}}
              </div>
            </template>
            <div class="flex" v-if="detailData.remarks">
              <div>操作备注：</div>
              <div style="flex:1;">{{detailData.remarks}}</div>
            </div>
          </div>
        </a-form-model-item>
        <div class="flex justify-between mb-2" v-if="detailData.refund_status == 4">
          <div></div>
          <a-button @click="handlerRetry">重试退款</a-button>
        </div>
      </a-form-model>
    </a-modal>
      
  </div>
</template>

<script>
import {
  getOrderRefundFlowList,
  retryOrderRefundFlow
} from "@/api/refund.js"

export default {
  components: {

  },
  data() {
    return {
      wrapperCol: { span: 18 },
      labelCol: { span: 6 },

      columns: [
        {
          title: '发起时间',
          dataIndex: 'add_time',
          align: 'center',
          width: 170,
        },{
          title: '订单号',
          dataIndex: 'order_no',
          align: 'center',
          width: 160,
          slots: {
            customRender: "order_no",
          },
        },{
          title: '退款方式',
          dataIndex: 'refund_mode',
          align: 'center',
          width: 80,
        },{
          title: '金额',
          dataIndex: 'amount',
          align: 'center',
          width: 80,
        },{
          title: '流水号',
          dataIndex: 'refund_flow_no',
          align: 'center',
          // width: 90,
        },{
          title: '第三方/人工录入流水号',
          dataIndex: 'refund_serial_no',
          align: 'center',
          // width: 180,
          slots: {
            customRender: "refund_serial_no",
          },
          slotsType: "all",
          slotsFunc: (val) => {
            return val.refund_serial_no || val.artificial_refund_flow_no
          },
        },{
          title: '成功/失败时间',
          dataIndex: 'refundsucc_time',
          align: 'center',
          width: 170,
          slots: {
            customRender: "refundsucc_time",
          },
          slotsType: "all",
          slotsFunc: (val) => {
            return val.refundsucc_time || val.refunderr_time
          },
        },{
          title: '状态',
          dataIndex: 'refund_status_show',
          align: 'center',
          width: 80,
        },{
          title: '操作',
          dataIndex: 'operation',
          align: 'center',
          width: 80,
          slots: {
            customRender: "operation",
          },
        },
      ],

      refundStatusList: [
        // （1-未退款，2-退款中，3-已退款，4-退款失败）
        {
          id: 1,
          name: "未退款"
        },{
          id: 2,
          name: "退款中"
        },{
          id: 3,
          name: "已退款"
        },{
          id: 4,
          name: "退款失败"
        },
      ],

      searchForm: {
        refund_status: 4,
        order_no: "",
        page: 1,
        page_count: 40,
      },
      total: 0,
      tableData: [],

      detailData: null,
      isShowDetail: false,

      retryLoading: false,
    };
  },
  async mounted() {
    this.handlerSearch()
  },
  methods: {
    handlerSearch(){
      this.searchForm.page = 1
      this.initData();
    },
    resetForm() {
      this.$refs.searchRef.resetFields();
      this.initData();
    },
    async initData(){
      const { data, code } = await getOrderRefundFlowList(this.searchForm);
      if (code === 0) {
        this.tableData = data.list.map(el=>{
          const item = this.refundStatusList.find(item=>item.id == el.refund_status)
          el.refund_status_show = item && item.name || el.refund_status
          return el
        })
        this.total = data.total_count
      }
    },


    handlerDetail(record){
      this.detailData = record
      this.isShowDetail = true
    },
    async handlerRetry(){
      if(this.retryLoading) return

      this.retryLoading = true
      const { code } = await retryOrderRefundFlow({
        refund_flow_no: this.detailData.refund_flow_no
      })
      this.retryLoading = false
      if (code == 0) {
        this.$message.success("已提交")
        this.initData()
        this.isShowDetail = false
      }
    },

    handlerOrderDetail(order_no){
      const newPage = this.$router.resolve({
        name: "order-detail",
        query: { order_no: order_no },
      });
      window.open(newPage.href, "_blank");
    },
    
  },
};
</script>

<style lang="less">

</style>
